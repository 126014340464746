import { Swiper } from "../../../swiper";
import "./style.css";

Array.from(document.querySelectorAll(".wj-question-swiper")).forEach((el) => {
  const [prevEl, nextEl] = Array.from(el.querySelectorAll("button"));

  new Swiper(el, {
    navigation: { prevEl, nextEl },
    autoHeight: true,
    grabCursor: true,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    speed: 600,
  });
});
