const textOutputPopup = document.querySelector(".text-output-popup");
const decode = require("decode-html");

if (textOutputPopup) {
  document.querySelectorAll(".publication-item").forEach((el) => {
    const plaintextButton = el.querySelector(
      ".publication-item__plaintext-button"
    );

    const props = {
      title: decode(el.getAttribute("data-title")),
      year: decode(el.getAttribute("data-year")),
      authors: decode(el.getAttribute("data-authors")),
      in: decode(el.getAttribute("data-in")),
    };

    plaintextButton.addEventListener("click", () => {
      textOutputPopup.dispatchEvent(
        new CustomEvent("output-text", {
          detail: {
            heading: el.getAttribute("data-i18n-citation"),
            text: `${props.authors} (${props.year}). ${props.title}. In: ${props.in}.`,
          },
        })
      );
    });
  });
}
