import { Swiper } from "../../swiper";
import "./style.css";

document.querySelectorAll(".stage").forEach((el) => {
  const [prevEl, nextEl] = Array.from(el.querySelectorAll("button"));

  new Swiper(el, {
    pagination: {
      el: el.querySelector(".stage__buttons"),
      bulletElement: "button",
      clickable: true,
    },
    loop: true,
    grabCursor: true,
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
  });
});
