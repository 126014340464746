import "./style.css";

document.querySelectorAll(".accordion").forEach((wrapper) => {
  const innerWrapper = wrapper.querySelector(".accordion-mobile");
  if (!innerWrapper) return;

  const tabs = Array.from(innerWrapper.querySelectorAll(".accordion__tab"));
  tabs.forEach((tab, i) => {
    const button = tab.querySelector(".accordion-button");
    if (!button) return;

    button.addEventListener("click", () =>
      wrapper.dispatchEvent(
        new CustomEvent("changetab", { detail: { tabID: i } })
      )
    );
  });

  wrapper.addEventListener("changetab", ({ detail: { tabID } }) => {
    const tab = tabs[tabID];
    const lastState = tab.getAttribute("data-active");
    tabs.forEach((tab) => {
      tab.setAttribute("data-active", "false");
    });
    tab.setAttribute("data-active", lastState === "true" ? "false" : "true");
  });
});
