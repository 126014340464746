import "./style.css";

Array.from(document.querySelectorAll(".course-description")).forEach(
  (wrapper) => {
    const btn = wrapper.querySelector("button");
    const text = wrapper.querySelector(".course-description--text");
    const sizeResolver = wrapper.querySelector(
      ".course-description--size-resolver"
    );

    btn.addEventListener("click", () => {
      if (!wrapper.getAttribute("data-visible"))
        wrapper.setAttribute("data-visible", "1");
      else wrapper.removeAttribute("data-visible");
    });

    let maxHeight = 0;
    new ResizeObserver(([{ contentRect }]) => {
      text.style.setProperty("--inner-height", `${contentRect.height}px`);
      maxHeight = Math.max(maxHeight, contentRect.height);
      text.style.setProperty("--max-inner-height", `${maxHeight}px`);
    }).observe(sizeResolver);
  }
);
