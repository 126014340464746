import copyToClipboard from "copy-to-clipboard";

import "./style.css";

document.querySelectorAll(".text-output-popup").forEach((el) => {
  const headingEl = el.querySelector(".text-output-popup__heading");
  const outputEl = el.querySelector(".text-output-popup__output");
  const closeButton = el.querySelector(".text-output-popup__close-button");
  const copyButton = el.querySelector(".text-output-popup__copy-button");
  const backdrop = el.querySelector(".text-output-popup__backdrop");

  const closePopup = () => el.setAttribute("data-visible", "false");
  closeButton.addEventListener("click", closePopup);
  backdrop.addEventListener("click", closePopup);

  el.addEventListener("output-text", ({ detail: { heading, text } }) => {
    headingEl.innerText = heading;
    outputEl.innerText = text;
    copyButton.disabled = false;
    el.setAttribute("data-visible", "true");
  });

  let copyTimeout = 0;
  copyButton.addEventListener("click", () => {
    copyToClipboard(outputEl.innerText);
    copyButton.disabled = true;
    clearTimeout(copyTimeout);
    copyTimeout = setTimeout(() => {
      copyButton.disabled = false;
    }, 3000);
  });
});
