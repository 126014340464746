import "./style.css";

const textarea = document.querySelector("#wj\\[question\\]");

if (textarea) {
  const countSpan = document.querySelector(
    ".wj-question-form--question-letter-count"
  );

  const updateCount = () => void (countSpan.innerHTML = textarea.value.length);

  textarea.addEventListener("input", () => {
    textarea.value =
      textarea.value != "\n" ? textarea.value.replace(/\n+/g, "\n") : "";

    updateCount();
  });

  updateCount();
}
