
document
    .querySelectorAll(".filter-form")
    .forEach((form) =>
        form
            .querySelectorAll(".project-state-filter__select")
            .forEach((el) =>
                el
                    .addEventListener("change", () => form.submit())
            )
    );