import "./style.css";
import { setPermissions } from "./permissions";

document.querySelectorAll(".cookie-manager").forEach((el) => {
  const agreeButton = el.querySelector(".cookie-manager__agree-btn");
  const prefsButton = el.querySelector(".cookie-manager__prefs-btn");
  const popup = el.querySelector(".cookie-manager__popup");

  const prefs = el.querySelector(".cookie-manager__prefs");
  const savePrefsButton = el.querySelector(".cookie-manager__prefs__save-btn");
  const cancelPrefsButton = el.querySelector(
    ".cookie-manager__prefs__cancel-btn"
  );

  const analyticsCheckbox = el.querySelector(
    ".cookie-manager__prefs__analytics"
  );
  const marketingCheckbox = el.querySelector(
    ".cookie-manager__prefs__marketing"
  );

  agreeButton.addEventListener("click", () =>
    setPermissions({
      analytics: true,
      marketing: true,
    })
  );

  prefsButton.addEventListener("click", () => {
    el.setAttribute("data-prefs-open", "true");
  });

  const removePopup = () => popup.remove();
  agreeButton.addEventListener("click", removePopup);
  prefsButton.addEventListener("click", removePopup);

  savePrefsButton.addEventListener("click", () =>
    setPermissions({
      analytics: analyticsCheckbox.checked,
      marketing: marketingCheckbox.checked,
    })
  );

  const removePrefs = () => prefs.remove();
  savePrefsButton.addEventListener("click", removePrefs);
  cancelPrefsButton.addEventListener("click", removePrefs);
});
