import "./style.css";

document.querySelectorAll(".accordion").forEach((wrapper) => {
  const innerWrapper = wrapper.querySelector(".accordion-desktop");

  const buttons = Array.from(
    innerWrapper.querySelectorAll(".accordion-button")
  );
  const contents = Array.from(
    innerWrapper.querySelectorAll(".accordion-desktop__content")
  );

  buttons.forEach((button, i) =>
    button.addEventListener("click", () =>
      wrapper.dispatchEvent(new CustomEvent("changetab", { detail: { tabID: i } }))
    )
  );

  wrapper.addEventListener("changetab", ({ detail: { tabID } }) => {
    function update(elements) {
      const tab = elements[tabID].parentNode;
      const lastState = tab.getAttribute("data-active");
      elements.forEach((element, i) => {
        if (i == tabID) return;
        element.parentNode.setAttribute("data-active", "false");
      });
      tab.setAttribute("data-active", lastState == "true" ? "false" : "true");
    }

    update(buttons);
    update(contents);
  });
});
