import "./button/style.css";
import "./mobile";
import "./desktop";

document
  .querySelectorAll(".accordion")
  .forEach((accordion) =>
    accordion.dispatchEvent(
      new CustomEvent("changetab", { detail: { tabID: 0 } })
    )
  );
