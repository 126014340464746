import "./style.css";

document.querySelectorAll(".search-filter").forEach((el) => {
  const input = el.querySelector(".search-filter__input");
  const updateState = () =>
    el.setAttribute("data-empty", !input.value ? "true" : "false");
  input.addEventListener("input", updateState);
  el.querySelector(".search-filter__clear-button").addEventListener(
    "click",
    () => {
      input.value = "";
      updateState();
    }
  );
});
