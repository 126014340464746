document
  .querySelectorAll(".filter-form")
  .forEach((form) =>
    form
      .querySelectorAll(".category-filter")
      .forEach((el) =>
        el
          .querySelector(".category-filter__select")
          .addEventListener("change", () => form.submit())
      )
  );
