const clickjacker = document.querySelector(".clickjacker");
export function createClickjacker(clickHandler) {
  if (clickjacker.getAttribute("data-enabled"))
    throw Error("Clickjacker already enabled");
  clickjacker.setAttribute("data-enabled", "true");

  clickjacker.addEventListener("click", clickHandler);

  const destroy = () => {
    clickjacker.removeAttribute("data-enabled");
    clickjacker.removeEventListener("click", clickHandler);
  };

  return destroy;
}
