import Cookies from "js-cookie";

/** Number of days after cookie expires. */
const cookieLifetime = 30;

function handlePermissions() {
  if (Cookies.get("ANALYTICS_ENABLED")) {
    // TODO: Requests to analytics service should be enabled here.
  }
}

export const setPermissions = ({ analytics, marketing }) => {
  Cookies.set("PERMISSIONS_SAVED", 1, { expires: cookieLifetime });
  Cookies.set("ANALYTICS_ENABLED", analytics ? 1 : 0, {
    expires: cookieLifetime,
  });
  Cookies.set("MARKETING_ENABLED", marketing ? 1 : 0, {
    expires: cookieLifetime,
  });

  handlePermissions();
};

handlePermissions();
