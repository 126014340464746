Array.from(document.querySelectorAll(".announcement--bg")).forEach(
    async (wrapper) => {
        const canvas = wrapper.querySelector("canvas");
        let width = 1;
        let height = 1;

        const updateCanvasSize = (w, h) => {
            const dpr = window.devicePixelRatio;
            width = dpr * w;
            height = dpr * h;
            canvas.width = width;
            canvas.height = height;
        };
        const updateCanvasSizeWithBB = () => {
            const rect = wrapper.getBoundingClientRect();
            updateCanvasSize(rect.width, rect.height);
        };

        new ResizeObserver(([{ contentRect }]) => {
            updateCanvasSize(contentRect.width, contentRect.height);
        }).observe(wrapper);
        window.addEventListener("resize", updateCanvasSizeWithBB);
        updateCanvasSizeWithBB();

        const ctx = canvas.getContext("2d");
        const render = (time) => {
            const pRadius = width / 24;
            const nParticles = 61;

            const hw = width / 2;
            const hh = height / 2;
            const s = Math.max(hw, hh) - pRadius;

            ctx.clearRect(0, 0, width, height);
            for (let i = 0; i < nParticles; i++) {
                const p = i / nParticles;
                const theta = p * 2 * Math.PI;
                const evo = (17 * p + 0.0001 * time) % 1;
                const x = evo * Math.sin(theta);
                const y = evo * Math.cos(theta);
                const alpha = 0.25 * (1 - Math.cos(2 * Math.PI * evo));
                ctx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
                ctx.beginPath();
                ctx.arc(
                    hw + s * x,
                    hh + s * y,
                    (0.2 + 0.8 * evo) * pRadius,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
            }

            requestAnimationFrame(render);
        };

        render();
    }
);
